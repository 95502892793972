const constants = {
  maxCategoryLength: 40,
  categoryEllipsis: ' ...',
  maxCategoryLimit: 6,
  minRecommendationDisplay: 3,
  maxDescriptionLength: 300,
  descriptionEllipsis: ' ...',
  algoliaEventEditions: [],
  eventSource: 'Atlas'
};

const participantRecommendationsConstants = {
  source: 0,
  minRecommendationToDisplay: 1,
  maxRecommendationsToDisplay: 2,
  minRecommendationReasonToDisplay: 3,
  descriptionLengthMedium: 100,
  descriptionLengthLarge: 220,
  descriptionEllipsis: ' &... ',
  eventSource: 'Atlas'
};

module.exports = { constants, participantRecommendationsConstants };
