import PropTypes from 'prop-types';

export default {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  logo: PropTypes.string,
  defaultImageUrl: PropTypes.string,
  isNew: PropTypes.bool,
  isInnovator: PropTypes.bool,
  recommendationType: PropTypes.number
};
