import React from 'react';
import { createRoot } from 'react-dom/client';
import RecommendationPanel from '../src/component/recommendationPanel/RecommendationPanel';

require('../external_css/popularRecommendations.css');

if (window && window.document) {
  const { elementId } = window.reactSettingsRecommendationList;
  const root = createRoot(document.getElementById(elementId));
  root.render(<RecommendationPanel
    appId={window.reactSettingsRecommendationList.props.algoliaConfig.appId}
    apiKey={window.reactSettingsRecommendationList.props.algoliaConfig.apiKey}
    eventEditionId={window.reactSettingsRecommendationList.props.context.eventEditionId}
    primaryLocale={window.reactSettingsRecommendationList.props.context.primaryLocale}
    interfaceLocale={window.reactSettingsRecommendationList.props.context.interfaceLocale}
    objectId={window.reactSettingsRecommendationList.props.context.exhibitingOrganisationId}
    displayCount={3}
    navigation={window.reactSettingsRecommendationList.props.navigation}
    assets={window.reactSettingsRecommendationList.props.assets}
    baseApiUrl={window.reactSettingsRecommendationList.props.baseApiUrl}
  />);
}
