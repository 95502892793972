import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { constants } from '../../shared/constants';
import { truncateString } from '../../shared/functions';

class RecommendationItemCategories extends Component {
  constructor() {
    super();
    this.state = {
    };
  }
  
  static get propTypes() {
    return {
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.string.isRequired
    };
  }

  render() {
    const renderComponent = this.props.categories && this.props.categories.length > 0;
    const { maxCategoryLength, categoryEllipsis, maxCategoryLimit } = constants;
    if (!renderComponent) {
      return null;
    }
    const spillOver = this.props.categories.length > maxCategoryLimit;

    return (
      <div className="recommendation-categories">
        <strong>{this.props.label}</strong>
        <ul>
          {
            this.props.categories.slice(0, maxCategoryLimit).map((category, index) =>
              (
                <li key={`cat-${index.toString()}`}>
                  <span className="label label-default label-in-list">{truncateString(category, maxCategoryLength, categoryEllipsis)}</span>
                </li>
              ))
          }
          { spillOver
            ? <li className="spillOver"><span className="label label-default label-in-list">{`+${this.props.categories.length - maxCategoryLimit}`}</span></li>
            : null }
        </ul>
      </div>
    );
  }
}

export default RecommendationItemCategories;
