import React, { Component } from 'react';
import Image from 'react-bootstrap/lib/Image';
import PropTypes from 'prop-types';
import ImageUtils from '../../shared/imageUtils';

class RecommendationItemLogo extends Component {
  constructor() {
    super();
    this.state = {
    };
  }
  
  static get propTypes() {
    return {
      imageUrl: PropTypes.string,
      defaultImageUrl: PropTypes.string,
      altText: PropTypes.string.isRequired
    };
  }

  static get defaultProps() {
    return {
      imageUrl: undefined,
      defaultImageUrl: undefined
    };
  }

  getImageUrl = () => {
    if (!this.props.imageUrl || this.props.imageUrl === '') {
      return this.props.defaultImageUrl;
    }
    return ImageUtils(this.props.imageUrl, 'desktop', 'small');
  };

  render() {
    const showLogo = (this.props.imageUrl && this.props.imageUrl !== '') || (this.props.defaultImageUrl && this.props.defaultImageUrl !== '');
    if (!showLogo) {
      return null;
    }
    const imageUrl = this.getImageUrl();

    return (
      <div className="recommendation-logo">
        <Image src={imageUrl} title={this.props.altText} alt={this.props.altText} responsive />
      </div>
    );
  }
}

export default RecommendationItemLogo;
