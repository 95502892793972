import path from 'path';
import { resolve as urlResolve } from 'url';

const getResizedImage = (url, device, size) => {
  if (!url) return '';
  const extName = path.extname(url);
  const fileName =
                  extName === '.gif'
                    ? `${path.basename(url, extName)}.png`
                    : path.basename(url);
  return urlResolve(url, path.join(`${device}-${size}`, fileName));
};

export default getResizedImage;
