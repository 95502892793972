import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import PropTypes from 'prop-types';
import RecommendationItemPropTypes from './recommendationItemPropTypes';
import RecommendationItemLogo from './RecommendationItemLogo';
import RecommendationItemCategories from './RecommendationItemCategories';
import { truncateString } from '../../shared/functions';
import { constants } from '../../shared/constants';
import Mixpanel from '../../shared/mixpanel';
import useIntersect from '../../shared/hooks/useIntersect';

// eslint-disable-next-line react/destructuring-assignment
function RecommendationItem({ recommendationItem, translations, source }) {
  const { maxDescriptionLength, descriptionEllipsis } = constants;

  const recommendationFeaturesClassName =
    !recommendationItem.isNew && !recommendationItem.isInnovator
      ? 'recommendation-features hidden'
      : 'recommendation-features';
  const newExhibitorClassName =
    !recommendationItem.isNew ? 'new-exhibitor hidden' : 'new-exhibitor';
  const isInnovatorClassName =
    !recommendationItem.isInnovator ? 'innovator-exhibitor hidden' : 'innovator-exhibitor';

  const targetRef = useIntersect(() =>
    Mixpanel.trackRecommendationViewed(recommendationItem, source));

  return (
    <Col
      xs={12}
      sm={12}
      md={4}
      className="recommendationItem"
      key={recommendationItem.id}
      data-dtm="recommendations_viewed"
      data-dtm-actions="view"
      data-dtm-attributes={JSON.stringify({
        exhibitorId: recommendationItem.id,
        recommendationType: recommendationItem.recommendationType
      })}
    >
      <div className="recommendationItemContent">
        <div className="recommendationLogoWrapper">
          <a
            href={recommendationItem.link}
            data-dtm="recommendation_click"
            data-dtm-exhibiting-organisation-id={recommendationItem.id}
            data-dtm-exhibiting-organisation-name={recommendationItem.title}
            data-dtm-recommendation-type={recommendationItem.recommendationType}
            data-dtm-attributes={JSON.stringify({
              exhibitorId: recommendationItem.id,
              recommendationType: recommendationItem.recommendationType
            })}
            onClick={() =>
              Mixpanel.trackRecommendationClicked(recommendationItem, source)}
          >
            <RecommendationItemLogo
              imageUrl={recommendationItem.logo}
              defaultImageUrl={recommendationItem.defaultImageUrl}
              altText={recommendationItem.title}
            />
          </a>
          <div className={recommendationFeaturesClassName}>
            <span className={newExhibitorClassName}>
              {translations.features.new_exhibitor}
            </span>
            <span className={isInnovatorClassName}>
              {translations.features.innovator}
            </span>
          </div>
        </div>
        <div className="recommendation-title text-large">
          <a
            ref={targetRef}
            href={recommendationItem.link}
            data-dtm="recommendation_click"
            data-dtm-exhibiting-organisation-id={recommendationItem.id}
            data-dtm-exhibiting-organisation-name={recommendationItem.title}
            data-dtm-recommendation-type={recommendationItem.recommendationType}
            data-dtm-attributes={JSON.stringify({
              exhibitorId: recommendationItem.id,
              recommendationType: recommendationItem.recommendationType
            })}
            onClick={() =>
              Mixpanel.trackRecommendationClicked(recommendationItem, source)}
          >
            <strong>{recommendationItem.title}</strong>
          </a>
        </div>
        <div className="recommendation-description text-small">
          {truncateString(
            recommendationItem.description,
            maxDescriptionLength,
            descriptionEllipsis
          )}
        </div>
        <RecommendationItemCategories
          categories={recommendationItem.categories}
          label={translations.labels.categories}
        />
      </div>
    </Col>
  );
}

RecommendationItem.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  recommendationItem: PropTypes.shape(RecommendationItemPropTypes).isRequired,
  translations: PropTypes.shape({
    labels: PropTypes.shape({
      categories: PropTypes.string.isRequired
    }).isRequired,
    features: PropTypes.shape({
      innovator: PropTypes.string.isRequired,
      new_exhibitor: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  source: PropTypes.string
};

export default RecommendationItem;
