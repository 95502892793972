import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Equalizer from 'react-equalizer';
import TranslationService from '../../shared/translations';
import RecommendationItem from './RecommendationItem';
import { constants } from '../../shared/constants';
import { equalizeHeights } from '../../shared/functions';
import RecommendationService from './api/recommendationService';

class RecommendationPanel extends Component {
  constructor() {
    super();
    this.state = {
      recommendations: null
    };
  }

  static get defaultProps() {
    return {
      objectId: null
    };
  }
  
  static get propTypes() {
    return {
      eventEditionId: PropTypes.string.isRequired,
      objectId: PropTypes.string,
      primaryLocale: PropTypes.string.isRequired,
      interfaceLocale: PropTypes.string.isRequired,
      displayCount: PropTypes.number.isRequired,
      navigation: PropTypes.shape({
        exhibitorPublicDetailsUrlFormat: PropTypes.string.isRequired
      }).isRequired,
      assets: PropTypes.shape({
        defaultOrganisationListingLogoUrl: PropTypes.string.isRequired
      }).isRequired,
      baseApiUrl: PropTypes.string.isRequired
    };
  }

  componentDidMount() {
    RecommendationService.getRecommendationsFromMARS(
      this.props.displayCount,
      this.props.eventEditionId,
      this.props.primaryLocale,
      this.props.interfaceLocale,
      this.props.objectId,
      this.props.baseApiUrl
    )
      .then((response) => {
        this.setState(
          {
            recommendations: response
          },
          () => {
            if (window.matchMedia('(min-width: 400px)').matches) {
              const component = this.RecommendationPanel;
              if (component) {
                const titleElements = component.getElementsByClassName('recommendation-title');
                equalizeHeights(titleElements);
                const descriptionElements = component.getElementsByClassName('recommendation-description');
                equalizeHeights(descriptionElements);
                const categoryElements = component.getElementsByClassName('recommendation-categories');
                equalizeHeights(categoryElements);
              }
            }
          }
        );
      })
      .catch(() => {
        this.setState({ recommendations: null });
      });
  }

  populateDtmAttributeValue() {
    const dtmAttributes = [];

    for (let i = 0; i < this.state.recommendations.length; i++) {
      const dtmAttribute = { exhibitorId: this.state.recommendations[i].id, recommendationType: this.state.recommendations[i].recommendationType };
      dtmAttributes.push(dtmAttribute);
    }

    return { dtmAttributes };
  }

  render() {
    const translations = TranslationService.getTranslations(this.props.interfaceLocale);
    const { minRecommendationDisplay, eventSource } = constants;
    
    if (this.state.recommendations === null || this.state.recommendations.length < minRecommendationDisplay) {
      return null;
    }
    
    return (
      <div id="recommendationPanel" data-dtm-actions="exist" data-dtm="recommendations_exists" data-dtm-attributes={JSON.stringify(this.populateDtmAttributeValue())} ref={(c) => { this.RecommendationPanel = c; }}>
        <Row className="recommendation-row">
          <Equalizer
            byRow
            property="height"
            enabled={() =>
              window.matchMedia('(min-width: 400px)').matches}
          >
            {
              this.state.recommendations.map((rec) => {
                const recommendationItem = rec;
                recommendationItem.defaultImageUrl = this.props.assets.defaultOrganisationListingLogoUrl;
                return (
                  <RecommendationItem
                    translations={translations}
                    key={recommendationItem.id}
                    recommendationItem={recommendationItem}
                    exhibitorPublicDetailsUrlFormat={this.props.navigation.exhibitorPublicDetailsUrlFormat}
                    source={eventSource}
                  />
                );
              })
            }
          </Equalizer>
        </Row>
      </div>
    );
  }
}



export default RecommendationPanel;
