import deAT from './de-AT.json';
import enGB from './en-GB.json';
import esMX from './es-MX.json';
import frFR from './fr-FR.json';
import itIT from './it-IT.json';
import jaJP from './ja-JP.json';
import ruRU from './ru-RU.json';
import zhCN from './zh-CN.json';
import zhHK from './zh-HK.json';


const localeMap = {
  'de-AT': deAT,
  'en-GB': enGB,
  'es-MX': esMX,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ru-RU': ruRU,
  'zh-CN': zhCN,
  'zh-HK': zhHK
};

export default {
  getTranslations(locale) {
    const translationsForLocale = localeMap[locale];
    return translationsForLocale || localeMap['en-GB']; // Default to english
  }
};
