/* global document:true */
import { authController } from '@reedexpo/authorization-component-ui';

const authCookieName = 'RX_AUTH';
const clientIdCookieName = 'ClientId';
const idTokenCookieName = 'id_token';

const getCookies = () => {
  const cookies = {};
  if (typeof (window) === 'undefined') {
    return {};
  }
  document.cookie.split('; ').forEach((cookie) => {
    const [name, value] = cookie.split('=');
    cookies[name] = decodeURIComponent(value);
  });
  return cookies;
};

const usePcke = () => {
  if (typeof (window) === 'undefined') {
    return false;
  }
  return (window && window.authSettings && window.authSettings.usePkce);
};

const getAuthToken = async () => {
  if (usePcke()) {
    try {
      const t = await authController.getToken();
      return t;
    } catch (e) {
      return null;
    }
  }
  return Promise.resolve(getCookies()[authCookieName] || null);
};

export default {
  getAuthToken,
  getClientId: () =>
    getCookies()[clientIdCookieName] || null,
  getIDToken: () =>
    getCookies()[idTokenCookieName] || null
};
