import tracker from '@reedexpo/digital-tracking';

const trackRecommendationClicked = (item, source) => {
  try {
    tracker.track('Recommendation Engaged', {
      'Exhibitor ID': item.id,
      'Exhibitor Name': item.title,
      'Product Area': 'ACS',
      'Recommendation Type': item.recommendationType,
      'Recommendation Category': 'Exhibitor Recommendation',
      Source: source
    });
  } catch (error) {
    console.error(error);
  }
};

const trackParticipantRecommendationClicked = (item, source) => {
  try {
    tracker.track('Recommendation Engaged', {
      'Exhibitor ID': item.organisationId,
      'Exhibitor Name': item.organisationName,
      'Product Area': 'ACS',
      'Recommendation Type': item.recommendationType,
      'Recommendation Category': 'Participant Recommendation',
      Source: source
    });
  } catch (error) {
    console.error(error);
  }
};

const trackParticipantRecommendationViewed = (item, source) => {
  try {
    tracker.track('Recommendation Viewed', {
      'Exhibitor ID': item.organisationId,
      'Exhibitor Name': item.organisationName,
      'Product Area': 'ACS',
      'Recommendation Type': item.recommendationType,
      'Recommendation Category': 'Participant Recommendation',
      Source: source
    });
  } catch (error) {
    console.error(error);
  }
};

const trackRecommendationViewed = (item, source) => {
  try {
    tracker.track('Recommendation Viewed', {
      'Exhibitor ID': item.id,
      'Exhibitor Name': item.title,
      'Product Area': 'ACS',
      'Recommendation Type': item.recommendationType,
      'Recommendation Category': 'Exhibitor Recommendation',
      Source: source
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  trackRecommendationClicked,
  trackParticipantRecommendationClicked,
  trackRecommendationViewed,
  trackParticipantRecommendationViewed
};
