function truncateString(stringToTruncate, maxLength, trail = '') {
  if (!stringToTruncate) {
    return '';
  }

  if (stringToTruncate.length <= maxLength || maxLength <= 0) {
    return stringToTruncate;
  }

  let breakingSpace = stringToTruncate.substring(0, maxLength).lastIndexOf(' ');

  if (breakingSpace === -1) {
    breakingSpace = maxLength;
  }

  return `${stringToTruncate.substring(0, breakingSpace)}${trail}`;
}


function equalizeHeights(elements) {
  if (!elements || elements.length === 0) {
    return;
  }

  let maxHeight = 0;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.clientHeight > maxHeight) {
      maxHeight = element.clientHeight;
    }
  }

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.style.height = `${maxHeight}px`;
  }
}

function responseToJson(stringResponse) {
  const response = stringResponse;
  const jsonData = JSON.parse(stringResponse.body);
  response.body = jsonData;
  return response;
}

module.exports = { truncateString, equalizeHeights, responseToJson };
